import { Injectable } from '@angular/core';
import { UserService } from '@shared/util/user';
import { firstValueFrom } from 'rxjs';
import { UserRoles } from '../shared/mdbos-user-roles';
import { LibraryClient, LibraryModel, PoiClient, PoiModel } from './webapi';

@Injectable({
  providedIn: 'root',
})
export class RoleFilterService {
  userLibraryName?: string;
  canFilterAll = false;
  userPoi?: PoiModel;
  userRole?: string;
  isLocationCoordinator = false;

  private library?: LibraryModel;

  constructor(
    private userService: UserService,
    private poiClient: PoiClient,
    private libraryClient: LibraryClient,
  ) {
    userService.currentOrImpersonateUser$.subscribe(() => this.resolve());
  }

  async resolve() {
    this.userRole = this.userService.currentOrImpersonateUser.role;

    if (this.userRole === UserRoles.Administrator || this.userRole === UserRoles.ReportCountryManager) {
      this.canFilterAll = true;
      return;
    }

    if (this.userRole === UserRoles.Poi) {
      await this.setPoiProperties();
      return;
    }

    if (
      this.userRole === UserRoles.ReadConsultant ||
      this.userRole === UserRoles.LibraryCoordinator ||
      this.userRole === UserRoles.EducationManager
    ) {
      await this.setConsultantAndLibraryUserProperties();
      return;
    }

    if (this.userRole === UserRoles.LocationCoordinator) {
      await this.setLocationCoordinatorProperties();
      return;
    }
  }

  private async setPoiProperties() {
    this.userPoi = await firstValueFrom(this.poiClient.getPoi());
  }

  private setLocationCoordinatorProperties() {
    this.isLocationCoordinator = true;
  }

  private async setConsultantAndLibraryUserProperties() {
    if (!this.library) {
      this.library = await firstValueFrom(this.libraryClient.getLibrary());
    }
    this.userLibraryName = this.library.name;
  }
}
